import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CsxFlexNgOidcComponent } from './csx-flex-ng-oidc.component';
import { AuthenticateService } from './oidc/services/authenticate.service';
import { OIDCLoaderService } from './oidc/services/oidc/oidc-loader.service';
import { Browser } from './oidc/shared/auth-browser';
import { UserService } from './oidc/services/user/user.service';
import { OidcService } from './oidc/services/oidc/oidc.service';
import { User } from './models/User';
import { FlexService } from './services/flex.service';
import { AuthStates } from './constants/authstates.enum';
import { AuthService } from './services/auth.service';

export function initialize(loader: OIDCLoaderService) {
  // We need to do something other than the lambda, otherwise
  // ng build will complain stating that lambdas are not supported
  console.debug('Executing preInitialize');
  return () => loader.initialize();
}

@NgModule({
  declarations: [
    CsxFlexNgOidcComponent
  ],
  imports: [
  ],
  exports: [
    CsxFlexNgOidcComponent
  ],
  providers: [AuthService, User, Browser],
})
export class CsxFlexNgOidcModule { 

  static initialize(): ModuleWithProviders<CsxFlexNgOidcModule> {
    return {
      ngModule: CsxFlexNgOidcModule,
      providers: [
        OIDCLoaderService,
        User,
        OidcService,
        FlexService,
        AuthService,
        {
          provide: APP_INITIALIZER,
          useFactory: initialize,
          deps: [OIDCLoaderService],
          multi: true
        },
      ]
    };
  }
}
