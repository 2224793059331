export const appConfigMapping = {
  
  security: {
    securityDomains: (<any>window).appConfig.security.securityDomains,
    clientId: (<any>window).appConfig.security.clientId,
    roleArn: (<any>window).appConfig.security.roleArn,
    scope: (<any>window).appConfig.security.scope,
    autoConfigUrl: (<any>window).appConfig.security.autoConfigUrl,
    url: (<any>window).appConfig.security.url,
    audienceUrl: (<any>window).appConfig.security.audienceUrl,
    logoutUrl: (<any>window).appConfig.security.logoutUrl,
    openIdConnectUrl: (<any>window).appConfig.security.openIdConnectUrl,
    redirectUri: (<any>window).appConfig.security.redirectUri,
    openIdRoleArn: (<any>window).appConfig.security.openIdRoleArn
  },
  api: {
    // You need to update this with the list of your APIs (needs to match app.config.js)
  }
};