import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../environments/environment";

declare const AWS: any;
declare const apigClientFactory: any;
declare const FLEXPROPS: any;
declare var window: any;

@Injectable({
  providedIn: "root",
})
export class AWSService {
  apigClient: any;
  awsIniatilized: boolean = false;

  constructor() {}

  initiateAWS(): Observable<any> {
    return Observable.create((observer: any) => {
      AWS.config.region = "us-east-1";

      AWS.config.credentials = new AWS.WebIdentityCredentials(
        {
          RoleArn: environment.appConfig.security.roleArn,
          WebIdentityToken: sessionStorage.getItem("token"), // token from identity service
          RoleSessionName: "web", // optional name, defaults to web-identity
        },
        {
          httpOptions: {
            timeout: 0,
          },
        }
      );

      AWS.config.credentials.get((err: any) => {
        if (err) observer.error(err);
        else {
          this.apigClient = apigClientFactory.newClient({
            invokeUrl: FLEXPROPS.invokeUrl,
            accessKey: AWS.config.credentials.accessKeyId,
            secretKey: AWS.config.credentials.secretAccessKey,
            sessionToken: AWS.config.credentials.sessionToken,
          });
          
          sessionStorage.setItem(
            "aws",
            JSON.stringify({
              accessKey: AWS.config.credentials.accessKeyId,
              secretKey: AWS.config.credentials.secretAccessKey,
              sessionToken: AWS.config.credentials.sessionToken,
            })
          );
          this.awsIniatilized = true;
          observer.next("AWS Initialized");
          observer.complete();
        }
      });
    });
  }

  checkAWSStatus() {
    if (!this.awsIniatilized && sessionStorage.getItem("aws")) {
      let awsStore = sessionStorage.getItem("aws") || '';
      this.apigClient = apigClientFactory.newClient(
        JSON.parse(awsStore)
      );
    }
  }

  getApigClient() {
    this.checkAWSStatus();
    return this.apigClient;
  }
}
