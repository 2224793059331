import { Injectable, ComponentFactoryResolver } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FlexService } from "./flex.service";
import { User } from "../models/User";
import { AuthStates } from "../constants/authstates.enum";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { parseString } from "xml2js";
import * as fs from "fs";
import { Credentials, WebIdentityCredentials, Lambda, S3, SecretsManager } from "aws-sdk";
import * as _ from "underscore";
import * as AWS from "aws-sdk";

declare var csxInitOptions: any;
declare var sjcl: any;
declare var apigClientFactory: any;
declare var Locker: any;

@Injectable()
export class AuthService {
  csxInitOptionsExists = false;
  lastRequestUrl = "";
  lastPageBeforeLogIn = null;
  sessionRefreshInProgress = false;
  retryFlexLogin = false;
  exclusiveCount: number = 0;
  inclusiveCount: number = 0;
  inclusiveAuth: boolean = false;
  exclusiveAuth: boolean = false;
  INVALID_CREDENTIALS_MSG = "Invalid Credential";
  CONTACT_US_MSG = "If error persists, please contact CSX Helpdesk at 1-800-243-7743 to open a TCIS ticket for MOBILEAPPS-SUPPORT";
  OFFLINE_ERR_MSG = "A Connection Error Occurred -- Are you offline? " + this.CONTACT_US_MSG;
  IDP_ERR_MSG = "An Error Occurred With Identity Provider. " + this.CONTACT_US_MSG;
  AWS_INIT_ERR_MSG = "An Error Occurred Creating Flex Session. " + this.CONTACT_US_MSG;
  AWS_UNAUTHORIZED_MSG = "An Error Occured Authenticating with AWS. Please verify the time and date on your device. " + this.CONTACT_US_MSG;
  OFFLINE_LOGIN_ERR_MSG = "An Error Occurred Initializing Local Storage. " + this.CONTACT_US_MSG;
  SIGNOUT_ERR_MSG = "An Error Occurred During Signout Call. " + this.CONTACT_US_MSG;


  private isAuthorizedSubject = new BehaviorSubject<AuthStates>(
    AuthStates.UNKNOWN
  );

  csxAuth = {
    initSecureStore: (
      collectionName: any,
      success: any,
      fail: any,
      callAdapter: any,
      searchFields: any
    ) => {
      //Object that defines all the collections
      var collections: any = {};

      //Object that defines the 'userCollection' collection
      collections[collectionName] = {};

      if (callAdapter) {
        //Optional Worklight Adapter integration
        collections[collectionName].adapter = {
          name: "http_csx_ent",
          load: {
            procedure: "getUserRequestInfo",
            params: [],
            key: "user"
          },
          accept: (adapterResponse: any) => {
            return adapterResponse.status === 200;
          },
          timeout: 3000
        };
      }

      collections[collectionName].searchFields = searchFields;

      //Optional options object
      var options: any = {};

      var userName = (<HTMLInputElement>(
        document.getElementById("usernameInputField")
      )).value.toUpperCase();
      userName = sjcl.hash.sha256.hash(userName);

      //Optional username
      options.username = userName;

      //Optional password
      options.password = userName;

      //Optional local key generation flag
      options.localKeyGen = false;

      //Optional clear flag
      options.clear = false;

      Locker.init(collections, options)

        .then(() => {
          success();
        })
        .fail((errorObject: any) => {
          console.debug(
            "Flex:Locker.init failed" + JSON.stringify(errorObject)
          );
          if (
            errorObject.err == -2 ||
            errorObject.msg == "PROVISION_TABLE_SEARCH_FIELDS_MISMATCH"
          ) {
            console.debug(
              "Flex:Locker.init failed .. Something changed in JSONStore.. " +
                collectionName
            );
            this.csxAuth.removeJSONStore(
              collectionName,
              success,
              fail,
              callAdapter
            );
          }
          fail();
        });
    },

    getAuthSearchFields: () => {
      return { id: "string" };
    },

    removeJSONStore: (collectionName: any, success: any, fail: any, callAdapter: any) => {
      this.csxAuth.initSecureStore(
        collectionName,
        () => {
          Locker.get(collectionName)
            .removeCollection()
            .then(() => {
              console.debug("Flex:Locker. Removed " + collectionName);
              Locker.closeAll().then(() => {
                console.debug("Flex:Locker. Closed before re-initializing.");
                this.csxAuth.initSecureStore(
                  collectionName,
                  success,
                  fail,
                  callAdapter,
                  this.csxAuth.getAuthSearchFields()
                );
              });
            })
            .fail((errorObject: any) => {
              fail();
            });
        },
        fail,
        false,
        {}
      );
    },

    // setupOfflineAuth: () => {
    //   console.debug("Flex:Setting up offline authentication ");

    //   var collectionName = "userCollection";
    //   var userName = (<HTMLInputElement>(
    //     document.getElementById("usernameInputField")
    //   )).value.toUpperCase();

    //   this.csxAuth.initSecureStore(
    //     collectionName,
    //     () => {
    //       var query: any = {};
    //       query.id = userName;
    //       var options = {
    //         push: false
    //       };
    //       Locker.get(collectionName)

    //         .remove(query, options)

    //         .then((numberOfDocumentsRemoved: any) => {
    //           //handle success
    //           Locker.get(collectionName)
    //             .load()

    //             .then((numberOfDocumentsLoaded: any) => {
    //               Locker.get(collectionName)
    //                 .find(query, options)

    //                 .then((arrayResults: any) => {
    //                   //arrayResults = [{_id: 1, json: {name: 'carlos', age: 99}}]
    //                   if (arrayResults && arrayResults.length > 0) {
    //                     this.Flex.updateUser(arrayResults[0].json);

    //                     var p = (<HTMLInputElement>(
    //                       document.getElementById("passwordInputField")
    //                     )).value;
    //                     p = sjcl.hash.sha256.hash(p);

    //                     arrayResults[0].json.passwordHash = p;

    //                     Locker.get(collectionName).replace(
    //                       arrayResults[0],
    //                       options
    //                     );
    //                     this.csxAuth.authenticationSuccess();
    //                   } else {
    //                     console.debug(
    //                       "not arrayResults && arrayResults.length > 0"
    //                     );
    //                     this.csxAuth.authenticationFailed(this.OFFLINE_LOGIN_ERR_MSG);
    //                   }
    //                 })
    //                 .fail((errorObject: any) => {
    //                   //handle failure
    //                   console.debug(
    //                     "Flex:Locker.findAll failed" +
    //                       JSON.stringify(errorObject)
    //                   );
    //                   this.csxAuth.authenticationFailed(this.OFFLINE_LOGIN_ERR_MSG);
    //                 });
    //             })
    //             .fail((errorObject: any) => {
    //               console.debug(
    //                 "Flex:Locker.load failed" + JSON.stringify(errorObject)
    //               );
    //             });
    //         })
    //         .fail((errorObject: any) => {
    //           console.debug(
    //             "Flex:Locker.remove failed" + JSON.stringify(errorObject)
    //           );
    //           this.csxAuth.authenticationFailed(this.OFFLINE_LOGIN_ERR_MSG);
    //         });
    //     },
    //     () => {
    //       this.csxAuth.authenticationFailed(this.OFFLINE_LOGIN_ERR_MSG);
    //     },
    //     true,
    //     this.csxAuth.getAuthSearchFields()
    //   );
    // },

    isOfflineAuthenticated: (success: any, fail: any) => {
      var collectionName = "userCollection";
      var userName = (<HTMLInputElement>(
        document.getElementById("usernameInputField")
      )).value.toUpperCase();
      this.csxAuth.initSecureStore(
        collectionName,
        () => {
          var options = {
            push: false
          };

          var query: any = {};
          query.id = userName;

          Locker.get(collectionName)
            .find(query, options)

            .then((arrayResults: any) => {
              //arrayResults = [{_id: 1, json: {name: 'carlos', age: 99}}]
              if (
                arrayResults &&
                arrayResults.length > 0 &&
                arrayResults[0].json
              ) {
                var user = arrayResults[0].json;

                var pwdHash = (<HTMLInputElement>(
                  document.getElementById("passwordInputField")
                )).value;
                var userName = (<HTMLInputElement>(
                  document.getElementById("usernameInputField")
                )).value.toUpperCase();

                pwdHash = JSON.stringify(sjcl.hash.sha256.hash(pwdHash));

                var userPwdHash = JSON.stringify(user.passwordHash);

                if (pwdHash == userPwdHash && userName == user.id) {
                  this.Flex.updateUser(user);
                  success();
                } else {
                  fail();
                }
              } else {
                console.debug("not arrayResults && arrayResults.length > 0");
                fail();
              }
            })
            .fail((errorObject: any) => {
              //handle failure
              console.debug(
                "Flex:Locker.findAll failed" + JSON.stringify(errorObject)
              );
              fail();
            });
        },
        fail,
        false,
        this.csxAuth.getAuthSearchFields()
      );
    },

    anotherUserLoggedIn: (response: any) => {
      var result = true;
      if (response != null && response.responseText != null) {
        if (
          response.responseText.indexOf("pkmslogout") != -1 ||
          (response.responseText.indexOf(
            "UnauthorizedSessionRequestException"
          ) != -1 &&
            response.status == 500)
        ) {
          result = true;
        } else {
          result = false;
        }
      }

      return result;
    },

    authenticationSuccess: () => {
      this.isAuthorizedSubject.next(AuthStates.AUTHORIZED);
    },

    onlineAuthenticationFailed: (err: any) => {
      // TELL that auth has failed
      console.log(err);

      //TODO add another condition here for standard locker login if applicable to app
      if (this.Flex.appProperty.customOfflineAuth) {
        //assume app will handle offline authentication properly
        this.csxAuth.authenticationSuccess();
      }
      else if(err.readyState === 0) {
        this.csxAuth.authenticationFailed(this.OFFLINE_ERR_MSG);
      } else {
        this.csxAuth.authenticationFailed(this.SIGNOUT_ERR_MSG);
      }
    },

    authenticationFailed: (errMsg?: any) => {
      this.isAuthorizedSubject.next(AuthStates.AUTH_FAILED);
    },

    authorizationSuccess: () => {
      if (this.Flex.online && !this.Flex.connectedAtLeastOnce) {
        // this.loginFormChallengeHandler.submitSuccess();
        this.Flex.connectedAtLeastOnce = true;
      }

      var userId = (<HTMLInputElement>(
        document.getElementById("usernameInputField")
      )).value.toUpperCase();
      this.Flex.user.idHash = sjcl.hash.sha256.hash(userId);

      var p = (<HTMLInputElement>document.getElementById("passwordInputField"))
        .value;
      this.Flex.user.passwordHash = sjcl.hash.sha256.hash(p);

      (<HTMLInputElement>document.getElementById("passwordInputField")).value =
        "";
      //var mainPageId = '#invoiceExpenseWorklist';

      if (this.Flex.online) {
        this.Flex.offlineLogin = false;
      } else {
        this.Flex.offlineLogin = true;
      }

      console.log("DISABLED ON DEMAND !!!");

      //TELL that auth is successful
      this.isAuthorizedSubject.next(AuthStates.AUTHORIZED);
    },

    authorizationFailed: () => {

      //TELL that auth is failed

      this.isAuthorizedSubject.next(AuthStates.UNAUTHORIZED);
    },

    

    verifyAuthorization: (user: any) => {
      var result = false;

      if (user) {
        var inclusive =
          typeof csxInitOptions.authorization.inclusive == "object"
            ? csxInitOptions.authorization.inclusive.groups
            : null;

        if (inclusive && inclusive.length > 0) {
          var incCnt = 0;
          for (var key in inclusive) {
            if (user.groups.indexOf(inclusive[key]) > -1) {
              incCnt++;
              if (!csxInitOptions.authorization.inclusive.all) {
                result = true;
                break;
              }
            }
          }

          if (
            csxInitOptions.authorization.inclusive.all &&
            incCnt == inclusive.length
          ) {
            result = true;
          }
        } else {
          result = true;
        }

        var exclusive =
          typeof csxInitOptions.authorization.exclusive == "object"
            ? csxInitOptions.authorization.exclusive.groups
            : null;

        if (exclusive && exclusive.length > 0) {
          var incCnt = 0;
          for (var key in exclusive) {
            if (user.groups.indexOf(exclusive[key]) > -1) {
              incCnt++;
              if (!csxInitOptions.authorization.exclusive.all) {
                result = false;
                break;
              }
            }
          }

          if (
            csxInitOptions.authorization.exclusive.all &&
            incCnt == exclusive.length
          ) {
            result = false;
          }
        }
      }

      if (result) {
        this.csxAuth.authorizationSuccess();
      } else {
        this.csxAuth.authorizationFailed();
      }
    },

    getUrl: () => {
      return location.protocol + "//" + location.host;
    },

    logout: () => {
      this.lastPageBeforeLogIn = null;
      this.Flex.user.removeListener(this.csxAuth.verifyAuthorization);
      this.csxAuth.submitSignoutRequest().subscribe(() => {
        console.log("Log out was successful!");
        this.isAuthorizedSubject.next(AuthStates.LOGGED_OUT);
      });
    },

    submitSignoutRequest: (): Observable<any> => {
      var data = "SingleSignOut=SingleSignOut&SignOut=Sign+Out";
      var headers = new HttpHeaders();
      return this.http.post(
        this.Flex.inBrowser() ? this.Flex.browserIdpUrl : this.Flex.idpUrl,
        data,
        {
          headers: headers,
          responseType: "text"
        }
      );
    },


    setOAuthUserAttributes: (jwtResponse: any) => {
    
      this.Flex.user.email = jwtResponse.email;
      this.Flex.user.lastName = jwtResponse.last_name;
      this.Flex.user.firstName = jwtResponse.first_name;
      this.Flex.user.id = jwtResponse.racf;
      this.Flex.user.groups = [];
    },

    initiateAwsClients: () =>{

      let parseJwt = (token: any) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
      };
  
      let idToken = '';
      if (localStorage.getItem('tokenResponse')) {
        let tokenResponse = localStorage.getItem('tokenResponse') || '';
        idToken = JSON.parse(tokenResponse).idToken;
      }
  
      let idResponse = parseJwt(idToken);
      this.csxAuth.setOAuthUserAttributes(idResponse);
      let user = idResponse.racf;

      AWS.config.region = "us-east-1";
  
  
      let credentials = new WebIdentityCredentials(
        {
          RoleArn: this.Flex.appProperty.openIdRoleArn,
          WebIdentityToken: idToken, // token from identity service
          RoleSessionName: "oidc-flex", // optional name, defaults to web-identity
        },
        {
          region: "us-east-1",
          httpOptions: {
            timeout: 0,
          },
        }
      );
  
      credentials.get((err: any) => {
          if (err){
            console.log(err);
            this.isAuthorizedSubject.next(AuthStates.AUTH_FAILED);
            this.Flex.sessionRefreshInProgress = false;
          } 
          else {
  
            sessionStorage.setItem(
              "aws",
              JSON.stringify({
                accessKey: credentials.accessKeyId,
                secretKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
              })
            );
            //let awsConfig = JSON.parse(sessionStorage.getItem('aws'));
            var apigClient = apigClientFactory.newClient({
              accessKey: credentials.accessKeyId,
              secretKey: credentials.secretAccessKey,
              sessionToken: credentials.sessionToken,
            });
            var lambda = new Lambda({
              accessKeyId: credentials.accessKeyId,
              secretAccessKey: credentials.secretAccessKey,
              sessionToken: credentials.sessionToken,
              region: 'us-east-1',
              convertResponseTypes: false,
              httpOptions: {
                timeout: 300000
              }
            });
            var s3 = new S3({
              accessKeyId: credentials.accessKeyId,
              secretAccessKey: credentials.secretAccessKey,
              sessionToken: credentials.sessionToken,
              region: 'us-east-1',
            });
            var secrets = new SecretsManager({
              accessKeyId: credentials.accessKeyId,
              secretAccessKey: credentials.secretAccessKey,
              sessionToken: credentials.sessionToken,
              region: 'us-east-1',
            });
  
            this.Flex.init(apigClient, lambda, s3, secrets, user).then( (result) => {
              this.Flex.sessionRefreshInProgress = false;
              this.csxAuth.authenticationSuccess();
            }, (err) => {
              this.Flex.sessionRefreshInProgress = false;
              this.isAuthorizedSubject.next(AuthStates.AUTH_FAILED);
              console.log(err);
            })
            .catch((err) => {
              this.Flex.sessionRefreshInProgress = false;
              this.isAuthorizedSubject.next(AuthStates.AUTH_FAILED);
              console.log('error initializing flex ' + err);
            });
            
            
          }
        });  
      
    },

    isValidSamlResponse(responseText: any) {
      if (responseText.indexOf("SAMLResponse") < 0) {
        return false;
      }
      return true;
    },

    showLogin: () => {
      console.log("Auth service show login");

      this.isAuthorizedSubject.next(AuthStates.UNAUTHORIZED);

    },

    timeoutShowLogin: () => {
      console.log("Auth service session timeout show login");

      this.isAuthorizedSubject.next(AuthStates.SESSION_TIMEOUT);
    }
  };

  constructor(private http: HttpClient, private Flex: FlexService) {
  }

  initializeSecurity() {
    this.csxInitOptionsExists = typeof csxInitOptions === "object";
  }

  detectMob() {
    //Will be replaced by a security check
    return !this.Flex.inBrowser();
  }

  getAuthenticatedUser(): User {
    return this.Flex.user;
  }

  isAuthorized(): Observable<AuthStates> {
    return this.isAuthorizedSubject.asObservable();
  }

  logout(clearSession?: boolean) {
    this.csxAuth.logout();
  }

  setUserAttributes(result: any) {
    var response = result["samlp:Response"];
    var samlAttributes = response.Assertion[0].AttributeStatement[0];
    this.Flex.user.email = samlAttributes.Attribute[0].AttributeValue[0];
    this.Flex.user.lastName = samlAttributes.Attribute[2].AttributeValue[0];
    this.Flex.user.firstName = samlAttributes.Attribute[3].AttributeValue[0];
    this.Flex.user.id = samlAttributes.Attribute[4].AttributeValue[0];
    this.Flex.user.groups = new Array();
    _.each(samlAttributes.Attribute[1].AttributeValue, (assertion: any) => {
      this.Flex.user.groups.push(assertion);
    });
  }

  checkGroups() {
    if (
      this.Flex.appProperty.exclusiveGroups.length !== 0 &&
      this.Flex.appProperty.exclusiveGroups !== undefined
    ) {
      this.Flex.user.groups.forEach(flexgroup => {
        this.Flex.appProperty.exclusiveGroups.forEach((exclusivegroup: any) => {
          if (flexgroup === exclusivegroup) {
            this.exclusiveCount++;
          }
        });
      });

      if (
        (this.Flex.appProperty.exclusiveAll === false &&
          this.exclusiveCount > 1) ||
        (this.Flex.appProperty.exclusiveAll === true &&
          this.Flex.appProperty.exclusiveGroups.length === this.exclusiveCount)
      ) {
        this.exclusiveAuth = true;
      } else {
        this.exclusiveAuth = false;
      }
    } else {
      this.exclusiveAuth = true;
    }

    if (
      this.Flex.appProperty.inclusiveGroups.length !== 0 &&
      this.Flex.appProperty.inclusiveGroups !== undefined
    ) {
      this.Flex.user.groups.forEach(flexgroup => {
        this.Flex.appProperty.inclusiveGroups.forEach((inclusivegroup: any) => {
          if (flexgroup === inclusivegroup) {
            this.inclusiveCount++;
          }
        });
      });

      if (
        (this.Flex.appProperty.inclusiveAll === false &&
          this.inclusiveCount > 1) ||
        (this.Flex.appProperty.inclusiveAll === true &&
          this.Flex.appProperty.inclusiveGroups.length === this.inclusiveCount)
      ) {
        this.inclusiveAuth = true;
      } else {
        this.inclusiveAuth = false;
      }
    } else {
      this.inclusiveAuth = true;
    }

    if (this.exclusiveAuth === true && this.inclusiveAuth === true) {
      return true;
    } else {
      return false;
    }
  }

}
