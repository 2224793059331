import { Injectable } from "@angular/core";
import { User } from "../models/User";
import { v4 } from "uuid";
//import { OidcService } from "../oidc/services/oidc/oidc.service";

declare var device: any;
declare var cordova: any;
declare var Locker: any;
declare var appConfig: any;

@Injectable()
export class FlexService {
  private awsClient: any;
  private awsLambda: any;
  private awsSecrets: any;
  private s3Client: any;
  private serverStage = appConfig.flex.stage;
  
  private sessionCheckTimer: any = null;
  sessionRefreshInProgress: boolean = false;
  user = new User();
  NOT_FOUND = "NOT_FOUND";

  isCordova = false;
  connectedAtLeastOnce = false;
  online = false;
  deviceNetworkStatus = false;
  offlineLogin = false;

  

  idpUrl = appConfig.flex.idpUrl;
  idpLogoutUrl = appConfig.flex.idpLogoutUrl;
  browserIdpUrl = appConfig.flex.browserIdpUrl;
  invokeUrl = appConfig.flex.invokeUrl;
  principalArn = appConfig.flex.principalArn;
  roleArn = appConfig.flex.roleArn;
  relyingParty = appConfig.flex.relyingParty;
  


  appProperty: any = {
    displayName: "%%NAME%%",
    appVersion: "%%VERSION%%",
    needsAuthorization: false,
    returnFromLogin: false,
    inclusiveAll: false,
    inclusiveGroups: [],
    exclusiveAll: false,
    exclusiveGroups: [],
    customOfflineAuth: false,
    idpLoginQueryParam: appConfig.flex.idpLoginQueryParam,
    browserIdpUrl: appConfig.flex.browserIdpUrl,
    invokeUrl: appConfig.flex.invokeUrl,
    principalArn: appConfig.flex.principalArn,
    roleArn: appConfig.flex.roleArn,
    idpUrl: appConfig.flex.idpUrl,
    openIdRoleArn: appConfig.flex.openIdRoleArn
    
  };

  deviceInfo: any = {
    uuid: null,
    userid: null,
    model: null,
    platform: null,
    application: this.appProperty.displayName,
    serial: null
    //model: device.model,
    //platform: device.platform
  };

  constructor() {
    console.log("Flex: constructed");
    //this.parseConfigStrings();

    if(typeof(cordova) == "object"){
      console.log("Cordova detected");
      document.addEventListener("deviceready", () => {
          this.onDeviceReady();
      });
    } else {
      this.onAppReady();
      if(typeof(Locker) === "object") {
        console.log("Locker detected");
       
      } else {

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =  '../assets/js/locker-browser.js';

        script.onerror = (error: any) => console.log("Error loading Locker for browser");
        document.getElementsByTagName('head')[0].appendChild(script);
      }
 
    }
  }


  configStringToBool(value: string): boolean {
    return value.toLowerCase() === "true" ? true : false;
  }

  configStringToArray(value: string): String[] {
    const arr = value.split(",");
    if (arr.length === 1 && (arr[0].length === 0 || arr[0].startsWith("%%"))) {
      return [];
    }
    return arr;
  }

  init = (aws: any, lambda: any, s3:any, secretsManager: any, user: any) => {
    return new Promise((resolve, reject) => {
      this.awsClient = aws || {};
      this.awsLambda = lambda || {};
      this.awsSecrets = secretsManager || {};
      this.deviceInfo.userid = user;
      this.s3Client = s3;
      this.awsClient
        .mobileflexdevicePost("", this.deviceInfo, "")
        .then((result: any) => {
          console.log(
            "%c Mobile Flex successfully initialized!",
            "background: green; color: white"
          );
          console.log("Success" + JSON.stringify(result));
          resolve(result);
          //$(':mobile-pagecontainer').pagecontainer('change', this.Flex.mainPage);
        })
        .catch((err: any) => {
          console.log(
            "%c Mobile Flex failed to initialize!",
            "background: red; color: white"
          );
          console.log("Fail" + JSON.stringify(err));
          reject(err);
        });
    });
  };

  getServerStage = () => {
    return this.serverStage;
  };

  invokeProcedure = (invocationData: any, options?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      var xhttp = new XMLHttpRequest();
      var parameters = "*";
      if (typeof invocationData.parameters === "object") {
        parameters = this._formatParams(invocationData.parameters);
      }

      options = options || {};

      //User provided callbacks
      var onSuccessCallback = options.onSuccess;
      var onFailureCallback = options.onFailure;

      invocationData.stage = this.getServerStage();

      if (this.getServerStage() === "local") {
        invocationData.racf = this.user.id;
        invocationData.app = this.appProperty.displayName;
      }

      console.log(invocationData);
      if (this.awsClient) {
        this.awsClient
          .mobileflexadaptersPost(parameters, invocationData, "")
          .then((result: any) => {
              var parsedResult = JSON.parse(result.data);
              if (onSuccessCallback) {
                onSuccessCallback(parsedResult);
              }
              resolve(parsedResult);
            },
            (err: any) => {
              if (err.status === 403) {
              } else if (onFailureCallback) {
                onFailureCallback(err);
              }
              reject(err);
            }
          );
      } else {
        reject(new Error("AWS Client has not been initialized"));
      }
    });
  };

  invokeLambda = (invocationData: any, options?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      var stage = this.getServerStage();

      options = options || {};

      //User provided callbacks
      var onSuccessCallback = options.onSuccess;
      var onFailureCallback = options.onFailure;
      var invocationContext = options.invocationContext;

      var invokeUrl = this.invokeUrl;
      var urlArray = invokeUrl.split("/");
      var pathComponent = urlArray[urlArray.length - 1];
      var lambdaFunction = "mobileflex-awshandler-" + pathComponent;

      invocationData.stage = stage;
      if (stage === "local") {
        invocationData.racf = this.user.id;
        invocationData.app = this.appProperty.displayName;
      }
      var payloadObject = { body: invocationData, path: pathComponent };
      var payload = JSON.stringify(payloadObject);
      var params = {
        FunctionName: lambdaFunction,
        Payload: payload
      };
      console.log(invocationData);
      if (this.awsLambda) {
        this.awsLambda.invoke(params,(err: any, result: any) => {
          if (err) {
            err.invocationContext = invocationContext;
            console.log(err);
            if (err.statusCode === 403) {
            
            } else if (onFailureCallback) {
              onFailureCallback(err);
            }
            reject(err);
          } else {
            let parsedResult: any = "";
            let parsedPayload: any = "";
            let parsedBody: any = "";

            if (typeof result.Payload === "string") {
              parsedPayload = JSON.parse(result.Payload);
            } else {
              parsedPayload = result.Payload;
            }

            if (typeof parsedPayload["body"] === "string") {
              parsedBody = JSON.parse(parsedPayload["body"]);
            } else {
              parsedBody = parsedPayload["body"];
            }

            if (typeof parsedBody === "string") {
              parsedResult = JSON.parse(parsedBody);
            } else {
              parsedResult = parsedBody;
            }

            if (invocationContext) {
              parsedResult["invocationContext"] = invocationContext;
            }
            if (onSuccessCallback) {
              onSuccessCallback(parsedResult);
            }
            resolve(parsedResult);
          }
        });
      } else {
        reject(new Error("AWS Lambda has not been initialized"));
      }
    });
  };

  getMqttCredentials = (secretName?: any): Promise<any> => {

    let credentials: any = null;

    let secretPromise = new Promise((resolve, reject) => {
      let secretId = 'mqtt_mrt';

      if (secretName) {
        secretId = secretName;
      }
      
      this.awsSecrets.getSecretValue({SecretId: secretId}, (err: any, data: any) => {
        if (err) {
          console.log(err);    
          reject(err);
        }
        else {
            if ('SecretString' in data) {
                var secret = data.SecretString;
                var parsedcredentials = JSON.parse(secret);
                // var env = Flex.getServerStage();
                var u = parsedcredentials.username;
                var p = parsedcredentials.password;
                credentials = {'u' : u, 'p': p};
                resolve(credentials);
            }
            resolve(credentials); 
        }

      });
    })
    
    return secretPromise;

  }

  sessionChecker = () => {
    let recheck = Math.floor(60000 * 5);
    if (this.sessionCheckTimer !== null) {
      return;
    }
    this.sessionCheckTimer = setInterval( () => {
      console.log('Checking session time');
      let tokenResponse = localStorage.getItem('tokenResponse') || '';
      let issuedAt = JSON.parse(tokenResponse).issuedAt;
      let expiresIn = JSON.parse(tokenResponse).expiresIn;

      let issueDate = new Date(issuedAt * 1000);
      let expireDate = new Date(issueDate.getTime() + 1000 * (expiresIn-2400));
      let currentDate = new Date();

      // if (currentDate > expireDate && !this.sessionRefreshInProgress) {
      //   if (this.oidcService) {
      //     this.sessionRefreshInProgress = true;
      //     console.log('Renewing access token');
         
      //     this.oidcService.tokenRequest();
      //   }
      // }
    }, recheck);
  };

  mobileFGHandle = () => {
    console.log('Checking session on application resume.');
    if (navigator.onLine) {
      let tokenResponse = localStorage.getItem('tokenResponse') || '';
      let issuedAt = JSON.parse(tokenResponse).issuedAt;
      let expiresIn = JSON.parse(tokenResponse).expiresIn;

      let issueDate = new Date(issuedAt * 1000);
      let expireDate = new Date(issueDate.getTime() + 1000 * (expiresIn-2400));
      let currentDate = new Date();

      // if (currentDate > expireDate && !this.sessionRefreshInProgress) {
      //   if (this.oidcService) {
      //     this.sessionRefreshInProgress = true;
      //     console.log('Renewing access token');
      //     this.oidcService.tokenRequest();
      //   }
      // }
     
    }

}


  _formatParams = (params: any) => {
    var returnParams = "";
    if (params) {
      returnParams = params;
    }
    return returnParams;
  };

  _uuidv4 = function() {
    return v4();
    // return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    // )
  };



  csxAppNetworkStatus = (networkStatus : any) => {
    this.deviceNetworkStatus = networkStatus;
    console.log(JSON.stringify(networkStatus));

    this.online = navigator.onLine;
    this.heartbeat();
  };

  heartbeat = () => {
    return new Promise((resolve, reject) => {
      if (this.online) {
        if (this.awsClient) {
          this.awsClient
            .mobileflexdevicePost("", this.deviceInfo, "").then((response: any) => {
              resolve(response);
            })
            .catch((result : any) => {
              
              reject();
            });
        } else {
          
          reject();
        }
      } else {
        reject();
      }
    });
   
  };

  updateUser = (user: any) => {
    this.user.id = user.id == this.NOT_FOUND ? "" : user.id;
    this.user.email = user.email == this.NOT_FOUND ? "" : user.email;
    this.user.firstName =
      user.firstName == this.NOT_FOUND ? "" : user.firstName;
    this.user.lastName = user.lastName == this.NOT_FOUND ? "" : user.lastName;
    this.user.groups = user.groups == this.NOT_FOUND ? "" : user.groups;
    this.user.dn = user.dn == this.NOT_FOUND ? "" : user.dn;
    this.user.internal = user.internal ? user.internal : false;
  };

  inBrowser = () => {
    if (this.deviceInfo) {
      return this.deviceInfo.platform === "browser";
    } else {
      return !(
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      );
    }
  };

  onDeviceReady = () => {
    console.log("device ready");
    this.deviceInfo.platform = device.platform;
    this.deviceInfo.serial = device.serial;
    this.deviceInfo.model = device.model;
    if (this.deviceInfo.platform === "browser") {
      var uuid = sessionStorage.getItem("uuid");
      if (uuid === null) {
        let newuuid = this._uuidv4();
        sessionStorage.setItem("uuid", newuuid);
      }
      this.deviceInfo.uuid = uuid;
    } else {
      this.deviceInfo.uuid = device.uuid;
    }

    this.online = true;

    document.addEventListener("offline", this.csxAppNetworkStatus, false);
    document.addEventListener("online", this.csxAppNetworkStatus, false);
  };

  onAppReady = () => {
    console.log("app ready");
    this.isCordova = false;
    this.deviceInfo.platform = "browser";
    this.deviceInfo.serial = "";
    this.deviceInfo.model = navigator.platform;
    var uuid = sessionStorage.getItem("uuid");

    if (uuid === null) {
      let newuuid = this._uuidv4();
      sessionStorage.setItem("uuid", newuuid);
    }

    this.deviceInfo.uuid = uuid;
    this.online = true;
    document.addEventListener("offline", this.csxAppNetworkStatus, false);
    document.addEventListener("online", this.csxAppNetworkStatus, false);
  };
}
