export class User {
    id = '';
    email = '';
    firstName = '';
    lastName = '';
    groups = new Array();
    dn = '';
    internal = false;
    passwordHash = '';
    idHash = '';
    passKey = '';
    listeners = new Array();

    addListener(listener: any) {
        if (this.listeners != null && this.listeners.indexOf(listener) < 0) {
            this.listeners[this.listeners.length] = listener;
        }
    };

    removeListener(listener: any) {
        if (this.listeners != null && this.listeners.indexOf(listener) > 0) {
            this.listeners.splice(this.listeners.indexOf(listener), 1);
        }
    };

    updateListeners(user: any) {
        if (this.listeners != null) {
            this.listeners.forEach(function(f) { f(user); });
        }
    };

}

