import { Injector, Injectable } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { OidcService } from './oidc.service';
import { AuthService } from './../../../services/auth.service'
import { Router } from '@angular/router';

declare var cordova: any;
@Injectable()
export class OIDCLoaderService {

    constructor(
        protected injector: Injector,
        private oidcSecurityService: OidcService,
        private router: Router,
        private authService: AuthService) {
    }

    private hashedEntryPoint: string = '';
    private _locationStrategy: LocationStrategy | null = null;

    public initialize() {
        // We need to listen to each authorization result in order to correct EC-4636 (fragile fix)
        
        console.log(this.oidcSecurityService.isAuthorized);
        //moved routing to login page to jquery code after document ready
        if (!this.oidcSecurityService.isAuthorized && !window.location.href.includes('redirect') && !window.location.href.includes('code')) {
            try {
                this.oidcSecurityService
                  .fetchServiceConfiguration()
                  .then(() => {
                      this.oidcSecurityService.makeAuthorizationRequest();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                
              } catch (error) {
                console.log("error connecting to Okta");
              }
        } else if (this.oidcSecurityService.isAuthorized) {
            if (this.oidcSecurityService.isValidToken) {
                this.authService.csxAuth.initiateAwsClients();
            } else {
                try {
                    this.oidcSecurityService
                      .fetchServiceConfiguration()
                      .then(() => {
                          this.oidcSecurityService.makeAuthorizationRequest();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    
                  } catch (error) {
                    console.log("error connecting to Okta");
                  }
            }
        }

        if (typeof cordova !== 'object') {
            this.oidcSecurityService
            .checkForAuthorizationResponse();
        }
         
    }
}