import { Injectable } from '@angular/core';
import { User } from './model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  body: string = '';
  appUser: User | undefined;
  promptEvent: Event | undefined;
  admin: boolean = false;

  constructor() { }

  parseJwt() {
    const jwt = sessionStorage.getItem('token') || '';
    const jwtParts = jwt.split('.');

    // An unsigned jwt still has 3 parts (empty string signature)
    if (jwtParts.length !== 3) {
      throw new Error('Token is not formatted correctly for a jwt');
    }
    this.decodePart(jwtParts[1]);
  }

  /**
   * Base64 decodes the specified value
   * @param part The individual JWT section
   */
  decodePart(part: string) {
    const base64 = part.replace(/-/g, '+').replace(/_/g, '/');
    const decoded = window.atob(base64);
    const user = JSON.parse(decoded);
    if (user.roles) {
      this.admin = true;
    } else {
      this.admin = false;
    }
    this.appUser = {
      name: user.first_name + ' ' + user.last_name,
      email: user.email.toLowerCase(),
      racf: user.racf.toLowerCase(),
      expiration: user.exp,
      roles: user.roles,
      isAdmin: this.admin
    };
  }

}
