import { Injectable, NgZone } from "@angular/core";
import {
  AuthorizationRequest,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  FetchRequestor,
  StorageBackend,
  LocalStorageBackend,
  log,
  Crypto, 
  DefaultCrypto,
  AuthorizationNotifier,
  AuthorizationRequestHandler,
  RedirectRequestHandler,
  BaseTokenRequestHandler,
  TokenRequestHandler,
  TokenResponse,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  StringMap,
  TokenRequest,

} from "@openid/appauth";

import { Browser} from "../../shared/auth-browser";
import { CordovaAuthorizationRequestHandler } from '../../shared/cordova-authorization-request-handler';
import { AuthenticateService } from "../authenticate.service";
import { NoHashQueryStringUtils } from "./nohash_string_utils";
import { Router } from '@angular/router';
import { ApigeeService } from "../apigee.service";
import { environment } from "../../../environments/environment";
import { AuthService } from "./../../../services/auth.service"

/* the Node.js based HTTP client. */
//const requestor = new NodeRequestor();

const openIdConnectUrl = environment.appConfig.security.openIdConnectUrl;

const clientId = environment.appConfig.security.clientId;

const redirectUri = environment.appConfig.security.redirectUri;
const scope = environment.appConfig.security.scope;
const state = undefined;

declare var window: any;
declare var cordova: any;
declare var Flex: any;
declare var axios: any;

@Injectable({
  providedIn: "root",
})
export class OidcService {
  private notifier: AuthorizationNotifier;
  private authorizationHandler: AuthorizationRequestHandler;
  private tokenHandler: BaseTokenRequestHandler;

  // state
  private configuration: AuthorizationServiceConfiguration | undefined;
  private request: AuthorizationRequest | undefined;
  private response: AuthorizationResponse | undefined;
  private code: string | undefined;
  private tokenResponse: TokenResponse | undefined;
  private crypto: Crypto;
  private previousCodeVerifier: string = '';
  private currentCodeVerifier: string = '';
  public loginRequest: boolean = true;
  private reauthenticationLogin: boolean = false;
  
  constructor(private auth: AuthenticateService, private router: Router,
     private zone: NgZone, private authService : AuthService
    ) {
    window['oidcService'] = this; 
    this.crypto = new DefaultCrypto();
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils()
    );
    this.tokenHandler = new BaseTokenRequestHandler();
    // set notifier to deliver responses
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    // set a listener to listen for authorization responses
    this.notifier.setAuthorizationListener((request, response, error) => {
      log("Authorization request complete ", request, response, error);

      if (response) {
        this.request = request;
        this.response = response;
        this.code = response.code;
        //this.showMessage(`Authorization Code ${response.code}`);
        this.fetchServiceConfiguration()
          .then(() => {
            this.makeTokenRequest();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  public tokenRequest() {
    this.zone.run(() => {
      this.loginRequest = false;
      this.fetchServiceConfiguration()
        .then(() => {
          this.makeTokenRequest();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }


  public refreshLogin() {  
    this.reauthenticationLogin = true;  
    try {
      let headers = {
          'Content-Type': 'application/json'
      }
      axios.delete(environment.appConfig.security.logoutUrl, {headers})
      .then((response: any) => {
        console.log(response);
      }, (error: any) => {
          console.log(error);
      });
      
      this.zone.run(() => {
        this.fetchServiceConfiguration()
          .then(() => {
              this.makeAuthorizationRequest();
          })
          .catch((err) => {
            console.log(err);
            this.reauthenticationLogin = false;  
          });
      });
      
    } catch (error) {
      console.log("error connecting to Okta");
      this.reauthenticationLogin = false; 
    }
  }

  showMessage(message: string) {
    console.log(message);
  }

  fetchServiceConfiguration() {
    const requestor = new FetchRequestor();
    this.tokenHandler = new BaseTokenRequestHandler(requestor);
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      openIdConnectUrl,
      requestor
    )
      .then((response) => {
        log("Fetched service configuration", response);
        this.configuration = response;
      })
      .catch((error) => {
        log("Error fetching configuration", error);
      });
  }

  makeAuthorizationRequest() {
    this.loginRequest = true;
    // create a request
    let request = new AuthorizationRequest(
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: state,
        
        extras: {
          prompt: "consent",
          access_type: "offline"
        },
      },
      undefined,
      true
    );

    if (this.configuration) {
      this.authorizationHandler.performAuthorizationRequest(
        this.configuration,
        request
      );
    } else {
      this.showMessage(
        "Fetch Authorization Service configuration, before you make the authorization request."
      );
    }
  }

  async makeTokenRequest() {
    if (!this.configuration) {
      this.showMessage("Please fetch service configuration.");
      this.reauthenticationLogin = false
      return;
    }

    let request: TokenRequest | null = null;
    if (this.code) {
      let extras: StringMap | undefined = undefined;
      if (this.request && this.request.internal) {
        extras = {};
        extras["code_verifier"] = this.request.internal["code_verifier"];
        this.currentCodeVerifier = this.request.internal["code_verifier"]; 
      }
       
      // use the code to make the token request.
      request = new TokenRequest({
        client_id: clientId,
        redirect_uri: redirectUri,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code: this.code,
        refresh_token: undefined,
        extras: extras,
      });
    } else if (sessionStorage.getItem('tokenResponse')) {
      let tokenResponse = sessionStorage.getItem('tokenResponse') || '';
      this.tokenResponse = JSON.parse(tokenResponse);
      this.previousCodeVerifier = JSON.parse(tokenResponse).codeVerifier;
      let extras: StringMap | undefined = undefined;
      if (this.previousCodeVerifier) {
        extras = {};
        extras["code_verifier"] = this.previousCodeVerifier;
      }
      
      let newCodeVerifier = this.crypto.generateRandom(128);
      let challenge: Promise<string|undefined> =
        this.crypto.deriveChallenge(newCodeVerifier).catch(error => {
          log('Unable to generate PKCE challenge. Not using PKCE', error);
          return undefined;
        });

      try {
        let result = await challenge;
        if (result) {
          // keep track of the code used.
          if (this.request){
            this.request.internal = this.request.internal || {};
            this.request.internal['code_verifier'] = newCodeVerifier;
          }
          this.currentCodeVerifier = newCodeVerifier;
          if(!extras) {
            extras = {};
          }
          extras['code_challenge'] = result;
          // We always use S256. Plain is not good enough.
          extras['code_challenge_method'] = 'S256';
        }
      } catch (e) {
        console.log(e);
      }
    

      // use the token response to make a request for an access token

      let newRefreshToken = '';
      if (this.tokenResponse && this.tokenResponse.refreshToken) {
        newRefreshToken = this.tokenResponse.refreshToken;
      }
      request = new TokenRequest({
        client_id: clientId,
        redirect_uri: '',
        grant_type: GRANT_TYPE_REFRESH_TOKEN,
        code: undefined,
        refresh_token: newRefreshToken,
        extras: extras,
      });
    }

    if (request) {
      this.tokenHandler
        .performTokenRequest(this.configuration, request)
        .then((response) => {
          let isFirstRequest = false;
          let tokenStore: any = '';
          if (this.tokenResponse) {
            // copy over new fields
            this.tokenResponse.accessToken = response.accessToken;
            this.tokenResponse.issuedAt = response.issuedAt;
            this.tokenResponse.expiresIn = response.expiresIn;
            this.tokenResponse.tokenType = response.tokenType;
            this.tokenResponse.scope = response.scope;
            this.tokenResponse.refreshToken = response.refreshToken;
            this.tokenResponse.idToken = response.idToken;
            tokenStore = this.tokenResponse;
            tokenStore.codeVerifier = this.currentCodeVerifier
          } else {
            isFirstRequest = true;
            this.tokenResponse = response;
            tokenStore = this.tokenResponse;
            tokenStore.codeVerifier = this.currentCodeVerifier
          }

          localStorage.setItem(
            "tokenResponse",
            JSON.stringify(tokenStore)
          );

          sessionStorage.setItem(
            "tokenResponse",
            JSON.stringify(tokenStore)
          );

          window.history.pushState({}, document.title, "" );
          // AWS Exchange
          //this.auth.exchangeJWT(this.tokenResponse.idToken);
          // unset code, so we can do refresh token exchanges subsequently
          this.code = undefined;
          console.log('-------------Auth token done---------------');

          
          if (this.loginRequest && !this.reauthenticationLogin) {
            this.authService.csxAuth.initiateAwsClients();
          } else {
            this.reauthenticationLogin = false;
            //this.authService.csxAuth.refreshAwsClients(); 
          }
        })
        .catch((error) => {
          log("Error creating access token", error);
          this.reauthenticationLogin = false
          if (Flex) {
            Flex.sessionRefreshInProgress = false;
          }
        });
    }
  }

  checkForAuthorizationResponse() {
    this.authorizationHandler.completeAuthorizationRequestIfPossible();
  }

  get tokens() {
    return this.tokenResponse;
  }

  get isAuthorized() {
    return (sessionStorage.getItem(
      "tokenResponse") || false)
  }

  get isValidToken() {

    let isValid = false;

    let issuedAt: any = '';
    let expiresIn: any = '';
    let apigeeToken: any = '';
    let refreshToken: any = '';

    let tokenResponse = sessionStorage.getItem('tokenResponse');

    if (typeof tokenResponse === "string") {
      apigeeToken = JSON.parse(tokenResponse).accessToken;
      refreshToken = JSON.parse(tokenResponse).refreshToken;
      issuedAt = JSON.parse(tokenResponse).issuedAt;
      expiresIn = JSON.parse(tokenResponse).expiresIn;

      let issueDate = new Date(issuedAt * 1000);
      let expireDate = new Date(issueDate.getTime() + 1000 * expiresIn);
      let now = new Date();

      if (now > expireDate) {
        isValid = false;
      } else {
        isValid = true;   
      }
    }
    
    return isValid;
  }
}

// export App
(window as any)["OIDCService"] = OidcService;
