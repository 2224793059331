declare var cordova: any;
declare var window: any;

export class Browser {
    protected onCloseFunction : Function = () => {};

    private browserRef : any | undefined;
    private cordovaBrowserRef : any | undefined;

    public async  closeWindow(): Promise<void> {
        
        if(this.browserRef != undefined) {
            this.browserRef.close(); 
        }      
         
    }

    browserCloseListener(closeBrowserEvent : Function){
        this.onCloseFunction = closeBrowserEvent;
    }

    public async openSilent(url: string) {
        if (typeof cordova !== 'object') {
            window.location.assign(url);
        } else {
            cordova.InAppBrowser.open(url, '_blank', 'location=no,zoom=no,hidden=yes');
        }
    }

    public async showWindow(url: string, callbackUrl?: string) : Promise<string | undefined> {
        
        return new Promise((resolve, reject) => {
            
            if (typeof cordova !== 'object') {
                this.browserRef = window.location.assign(url);
            } else {
                this.browserRef = cordova.InAppBrowser.open(url, '_blank', 'location=yes,zoom=no,hardwareback=no,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,toolbarcolor=#00467F,fullscreen=no');
            }

            if(this.browserRef != undefined) {
                this.browserRef.addEventListener('loadstart', (event: any) => {
                    
                    if ((event.url).indexOf('mrt.go.csx.com') > -1 || (event.url).indexOf('localhost:4200') > -1 
                    || (event.url).indexOf('mrt-dev.go-dev.csx.com') > -1 || (event.url).indexOf('mrt-qa.go-dev.csx.com') > -1
                    || (event.url).indexOf('mrt-staging.go-dev.csx.com') > -1) {
                        //this.browserRef.removeEventListener('exit', () => {});
                        window.ngRoute.navigate('./mrt-angular/loading'); 
                        this.browserRef.hide();
                        this.browserRef.close();

                        
                        const responseParameters = ((event.url).split('?')[1]).split('&');
                        const parsedResponse: any = {};
                        for (let i = 0; i < responseParameters.length; i++) {
                            parsedResponse[responseParameters[i].split('=')[0]] =
                            responseParameters[i].split('=')[1];
                        }
                        if (parsedResponse['code'] !== undefined &&
                            parsedResponse['code'] !== null) {
                                resolve(event.url);
                            
                        } else {
                            reject('Problem authenticating with Okta');
                        }  
                    }
                    
                });
            } else {
                reject(undefined);
            }
        
        });
    } 
        
       
}