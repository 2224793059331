import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

import { environment } from "../../environments/environment";
import { UserService } from "./user/user.service";
import { AWSService } from "./aws/aws.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticateService {
  constructor(
    private http: HttpClient,
    private awsApi: AWSService,
    private userService: UserService,
    private router: Router
  ) { }

  //clientId = environment.appConfig.security.clientId;
  clientId = "0oaph3frx6RnEAwVP0h7";
  scope = environment.appConfig.security.scope;
  apigClient: any;
  sessionToken: string = '';

  authenticateUser(un: string, pw: string): Observable<any> {
    return this.http.post(
      environment.appConfig.security.url,
      {
        username: un,
        password: pw,
      },
      { withCredentials: true }
    );
  }

  loginWithSession(): Observable<any>  {
    return this.http.get(
      this.createUrl()
    );
  }

  createUrl(): string {
    return `${environment.appConfig.security.audienceUrl}/v1/authorize?client_id=${this.clientId}&response_type=id_token&scope=${this.scope}&prompt=none&redirect_uri=http://localhost:4200&state=mrt&nonce=mrt&sessionToken=${this.sessionToken}&response_mode=fragment`;
  }

  exchangeJWT(jwt: string) {
    this.storeJWTInSession(jwt);
    this.awsApi.initiateAWS().subscribe((data: any) => {
      //this.router.navigate(["/menu-screen"]);
      console.log(this.awsApi.awsIniatilized);
    });
    this.userService.parseJwt();
  }

  storeJWTInSession(jwt: string) {
    sessionStorage.setItem("token", jwt);
  }

  logOut(): Observable<any> {
    return this.http.delete(environment.appConfig.security.logoutUrl, {
      withCredentials: true,
    });
  }
}
